<template>
	<div class="article is-flex is-flex-direction-column">
		<router-link
			v-if="fromPage === 'home'"
			class="article__image is-relative is-block"
			:to="
				`/articole/articol/${formatArticleTitle(article.titlu)}-${
					article.id
				}/`
			"
		>
			<img
				:src="`${databaseLink}/${article.imagine.url}`"
				:alt="article.titlu"
			/>
			<div
				class="article__overlay is-flex is-hidden-touch is-align-items-center is-justify-content-center"
			>
				<ion-icon name="link-outline"></ion-icon>
			</div>
		</router-link>
		<router-link
			v-else
			class="article__image is-relative is-block"
			:to="
				`${$route.path}articole/${formatArticleTitle(article.titlu)}-${
					article.id
				}/`
			"
		>
			<img
				:src="`${databaseLink}/${article.imagine.url}`"
				:alt="article.titlu"
			/>
			<div
				class="article__overlay is-flex is-hidden-touch is-align-items-center is-justify-content-center"
			>
				<ion-icon name="link-outline"></ion-icon>
			</div>
		</router-link>
		<ul class="article__meta is-flex is-justify-content-center py-3">
			<li class="is-flex is-align-items-center mr-3">
				<div class="icon">
					<ion-icon name="calendar-clear-outline"></ion-icon>
				</div>
				<span>{{
					moment(article.published_at).format('DD.MM.YYYY')
				}}</span>
			</li>
			<li
				v-if="article.autor && article.autor.length > 0"
				class="is-flex is-align-items-center mr-3"
			>
				<div class="icon">
					<ion-icon name="person-outline"></ion-icon>
				</div>
				<span>
					{{ article.autor }}
				</span>
			</li>
		</ul>
		<div
			class="is-flex is-flex-direction-column is-justify-content-space-between"
			style="height: 100%"
		>
			<router-link
				v-if="fromPage === 'home'"
				:to="
					`/articole/articol/${formatArticleTitle(article.titlu)}-${
						article.id
					}/`
				"
				class="article__title is-block mb-3 has-text-centered"
				>{{ article.titlu }}</router-link
			>
			<router-link
				v-else
				:to="
					`${$route.path}articole/${formatArticleTitle(
						article.titlu
					)}-${article.id}/`
				"
				class="article__title is-block mb-3 has-text-centered"
				>{{ article.titlu }}</router-link
			>
			<markdown-it-vue
				class="article__description"
				:content="article.descriere_scurta"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			article: {
				type: Object,
				require: true,
			},
			isDirectPath: Boolean,
			fromPage: String,
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
		},
		methods: {
			formatArticleTitle(name) {
				let str = name.toLowerCase();
				let charMap = {
					ă: 'a',
					â: 'a',
					î: 'i',
					ş: 's',
					ţ: 't',
				};
				let rx = /(ă|â|î|ş|ţ)/g;
				if (rx.test(str)) {
					str = str.replace(rx, function(m, key) {
						return charMap[key];
					});
				}
				str = str.replace(/[^a-z\d\s-]/gi, '');
				str = str.replace(/[\s-]+/g, ' ');
				str = str.replace(/\s/g, '-');
				return str;
			},
		},
	};
</script>

<style scoped lang="scss">
	.article {
		height: 100%;
		&__image {
			background-color: $primary;
			padding-top: 56.25%;
			overflow: hidden;
			&:hover {
				.article__overlay {
					opacity: 1;
				}
			}
			img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				height: auto;
			}
		}
		&__overlay {
			background-color: rgba(0, 0, 0, 0.3);
			color: $white;
			font-size: 3rem;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: opacity 0.3s ease-in-out;
		}
		&__meta {
			span {
				font-weight: $medium;
			}
		}
		&__title {
			font-size: 1.25rem;
		}
	}
</style>
